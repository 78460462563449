/**
 * OI colors schema
 */

$app-color-primary: #6a2c91;
$app-color-tone: #855c9d;
$app-color-primary-light-1: #855c9d;
$app-color-primary-light-3: #f0e9f4;

$app-color-white: #fff;
$app-color-black: #1f2020;
$app-color-gray-dark: #77706f;

$app-color-warning: #ffc107;
$app-color-danger: #dc3545;

$app-background-gradient: linear-gradient(90deg, #262262 0 , #85479b 74%, #6a2c91 98%);