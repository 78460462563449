// .orders-history-order-product
$orders-history-order-product-font-size: 90%;
$orders-history-order-product-margins: (
        "top": 0,
        "right": 0,
        "bottom":0,
        "left":0
);

// .orders-history-order-product .orders-history-order-product-thumbnail
$orders-history-order-product-thumbnail-width: 50px;
$orders-history-order-product-thumbnail-border: none;

$orders-history-order-product-thumbnail-margins: (
        "top": 0,
        "right": $spacer,
        "bottom": 0,
        "left": 0
);

// .orders-history-order-product + .orders-history-order-product
$orders-history-order-product__plus-margin-top: $spacer;

// .orders-history-order-product .info
$orders-history-order-product-info-paddings: (
        "top": 0,
        "right": 0,
        "bottom": 0,
        "left": 0
);

// .orders-history-order-product .status .control
$orders-history-order-product-status-control-text-align: right;

// .orders-history-order-product .status .control .btn-control
$orders-history-order-product-status-control-btn-control-font-size: 12px;
$orders-history-order-product-status-control-btn-control-text-align: right;

// .orders-history-order-product .request-cancel
$orders-history-order-product-request-cancel-paddings: (
        "left": 0
);

// .orders-history-order-product .transfer
$orders-history-order-product-transfer-paddings: (
        "left": 0
);

// .orders-history-order-product .tracking
$orders-history-order-product-tracking-paddings: (
        "left": 0
);
$orders-history-order-product-tracking-font-weight: normal;

// .orders-history-order-product .status
$orders-history-order-product-status-paddings: (
        "left": 0
);