.home-catalog-message {
  background: $panel-background-color;
  box-shadow: $panel-box-shadow;
  border-radius: $panel-border-radius;
  text-align: center;
  padding: $spacer*2 $spacer*3;
  font-size: 15px;

  @each $property, $value in $panel-borders {
    border-#{$property}: $value;
  }
}